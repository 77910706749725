import type { GetServerSideProps } from 'next';

import Router from './router';

export const getServerSideProps: GetServerSideProps = async ({ query, res }) => {
  res.setHeader('Cache-Control', 'public, s-maxage=30, stale-while-revalidate=3600');

  return { props: { language: query.lng ?? 'en' } };
};

export default Router;
